/* CREDIT: https://getbootstrap.com/docs/5.1/examples/sign-in/signin.css */

html,
body {
  height: 100%;
}

body {
  align-items: center;
}

.container-auth {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.container-auth .checkbox {
  font-weight: 400;
}

.container-auth .form-floating:focus-within {
  z-index: 2;
}

.container-auth .form-input-top {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-auth .form-input-middle {
  margin-bottom: -1px;
  border-radius: 0;
}

.container-auth .form-input-bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.auth-icon {
  color: #7713F7;
  font-size: 4rem;
}

.nav.nav-pills {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
}

.tab-content {
  margin-top: 0.5rem;
}

.ms-n5 {
  margin-left: -40px;
}

.text-purple {
  --bs-text-opacity: 1;
  color: #B95CF4 !important;
}

.has-margin {
  border-left: 2px solid #0dcaf0 !important;
  margin-right: 2px;
}
.no-margin {
  border-left: 2px solid transparent !important;
  margin-right: 2px;
}

.th-ticker {
  min-width: 55px;
}
.th-ticker-vol {
  min-width: 160px;
}
.th-industry {
  min-width: 100px;
}
.th-rating {
  min-width: 50px;
}
.th-priceFlat {
  min-width: 105px;
}
.th-pricePercent {
  min-width: 56px;
}
.th-signal {
  min-width: 90px;
}
.th-price {
  min-width: 50px;
}
.th-t {
  min-width: 95px;
}
.th-base {
  min-width: 90px;
}
.th-pullback {
  min-width: 115px;
}
.th-profit {
  min-width: 60px;
}
.th-break5 {
  min-width: 110px;
}
.th-rawDate {
  min-width: 100px;
}
.th-note {
  min-width: 200px;
}
